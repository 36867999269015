import Rails from '@rails/ujs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './css/app.scss';
import './splide/app';
import './js/ahoy';
import './utils/lozad';
import './navbar/main';
import './utils/sale_countdown/main';
import './analytics/analytics';
import './on_boarding/main';
import {OnBoardingGoal} from "./utils/models/OnBoardingGoal";

// DYNAMIC IMPORTING
// Only import reviews if #new_review is in the viewport and visible
const $addReviewButton = document.getElementById('new_review');
if ($addReviewButton) {
 import('./reviews/main');
}

Rails.start();

console.log('Hello World from Hiiker!');

// Attach event listener to the button
const giftButton = document.getElementById('giftCheckoutButton');
if (giftButton) {
  import('./on_boarding/main');
  giftButton.addEventListener('click', function (e) {
    e.preventDefault();
    if (window.user == null) {
      window.onBoardingVue.launchFor(OnBoardingGoal.CreateAccountForPurchase)
      return
    } else {
      window.location.href = window.giftCheckoutUrl;
    }
  });
}
